<template>
  <div>

  <Dashboard />


  </div>
</template>

<script>
import Dashboard from "@/components/portal/Dashboard";




export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Dashboard,
 
   
    
  },
  metaInfo: {
    title: "Welkom bij de portal van Kantoorflex | flexplekken in Nederland ",
    htmlAttrs: {
      lang: "NL",
      amp: true,
    },
  },
};
</script>