<template>
  <v-app id="inspire">
    <v-container>
      <v-app-bar color="rgba(0,0,0,0)" flat>
        <!-- <v-text-field
          label="Zoeken..."
          class="pt-5"
          filled
          prepend-inner-icon="mdi-magnify"
          dense
          solo
          flat
          background-color="grey lighten-4"
          rounded
        ></v-text-field> -->
        <v-spacer></v-spacer>

        <router-link to="/">
          <v-icon color="indigo">far fa-envelope</v-icon>
        </router-link>

        <v-btn icon dark class="">
          <v-icon color="orange darken-2">far fa-bell</v-icon>
        </v-btn>

        <v-avatar size="30" class="ml-2">
          <v-img src="@/assets/logo.png"></v-img>
        </v-avatar>
      </v-app-bar>

      <v-row>
        <v-col cols="12" sm="10">
          <div style="margin-top:50px">
            <v-toolbar flat color="rgba(0,0,0,0)" dense class="mt-n1">
              <h3>
                <span v-if="isMorning()">
                  {{ morning }} {{ customerInfo.name }}</span
                >

                <span v-if="isEvening()">
                  {{ evening }} {{ customerInfo.name }}</span
                >

                <span v-if="isNight()">
                  {{ night }} {{ customerInfo.name }}</span
                >

                <span v-if="!customerInfo"> Goeiedag kantoorflexer</span>
              </h3>
            </v-toolbar>
          </div>

          <v-row class="mt-n5">
            <div class="row" style="margin-top:20px">
              <div class="col-12 col-md-6 col-lg-4 mb-4">
                <router-link class="router-link" :to="{ name: 'Boekingen' }">
                  <div class="link-box">
                    <v-icon large color="green darken-2">
                      mdi-cart-variant
                    </v-icon>
                    <p class="link-box-title">{{ totalReservations }}</p>
                    <p class="link-box-subtitle">Aantal reserveringen</p>
                  </div>
                </router-link>
              </div>

              <div class="col-12 col-md-6 col-lg-4 mb-4">
                <router-link class="router-link" :to="{ name: 'Boekingen' }">
                  <div class="link-box">
                    <v-icon large color="error">
                      mdi-close-octagon-outline
                    </v-icon>
                    <p class="link-box-title">{{ totalCancelled }}</p>
                    <p class="link-box-subtitle">Aantal Annuleringen</p>
                  </div>
                </router-link>
              </div>
              <div class="col-12 col-md-6 col-lg-4 mb-4">
                <router-link class="router-link" :to="{ name: 'Boekingen' }">
                  <div class="link-box">
                    <v-icon large color="blue darken-2">
                      mdi-gavel
                    </v-icon>
                    <p class="link-box-title"> {{ totalPending }} </p>
                    <p class="link-box-subtitle">Wacht op goedkeuring</p>
                  </div>
                </router-link>
              </div>

 <div class="col-12 col-md-6 col-lg-4 mb-4">
                <router-link class="router-link" :to="{ name: 'Boekingen' }">
                  <div class="link-box">
                    <v-icon large color="green darken-2">
                      mdi-gavel
                    </v-icon>
                    <p class="link-box-title">{{ totalApproved }}</p>
                    <p class="link-box-subtitle">Aantal goedgekeurd</p>
                  </div>
                </router-link>
              </div>

              <div class="col-12 col-md-6 col-lg-4 mb-4">
                <router-link class="router-link" :to="{ name: 'Werkplekken' }">
                  <div class="link-box">
                    <v-icon large color="blue darken-2">
                      mdi-chair-rolling
                    </v-icon>
                    <p class="link-box-title">{{ totalOffices }}</p>
                    <p class="link-box-subtitle">Aantal Flexplekken</p>
                  </div>
                </router-link>
              </div>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import apiOwner from "../api/apiAppointment";
import apiUser from "../api/apiUser";

export default {
  name: "Home",

  data: () => ({
    customerInfo: [],
    workspaces: [],
    pending: [],
    approvedAppointments: [],
    cancelledAppointments: [],
    totalPending: null,
    totalApproved: null,
    totalCancelled: null,
    totalOffices: null,
    items: [],
    alert: false,
    text: "weekly",
    morning: "Goedemorgen",
    evening: "Goedemiddag",
    night: "Goedeavond",
    width: 2,
    radius: 10,
    padding: 8,
    lineCap: "round",
    value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
    fill: false,
    type: "trend",
    autoLineWidth: false,
  }),

  computed: {
  totalReservations() {
    return this.pending.length + this.approvedAppointments.length + this.cancelledAppointments.length;
  }
},


  async mounted() {
    apiOwner.getOwnerPendingAppointments().then((data) => {
      this.pending = data;
      this.totalPending = this.pending.length;
    });
    apiOwner.getOwnerApprovedAppointments().then((data) => {
      this.approvedAppointments = data;
      this.totalApproved = this.approvedAppointments.length;
    });

    apiOwner.getOwnerCancelledAppointments().then((data) => {
      this.cancelledAppointments = data;
      this.totalCancelled = this.cancelledAppointments.length;
    });
    apiUser.getCurrentUser().then((data) => {
      this.customerInfo = data;
    });

    apiOwner.getAllMyOffices().then((data) => {
      this.workspaces = data;
      this.totalOffices = this.workspaces.length;
    });
  },
  methods: {
    gotoInstagram() {
      window.open("https://www.instagram.com/kantoorflex/");
    },

    isMorning() {
      return new Date().getHours() < 12 ? true : false;
    },
    isEvening() {
      return new Date().getHours() >= 12 && new Date().getHours() <= 17
        ? true
        : false;
    },
    isNight() {
      return new Date().getHours() >= 17 && new Date().getHours() <= 24
        ? true
        : false;
    },
  },
};
</script>

<style scoped>
.router-link {
  color: black;
  text-decoration: none;
}
.link-box {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 5px 8px 0 rgba(0, 0, 0, 0.05);
  padding: 20px;
  font-size: 16px;
  text-align: center;
  /* min-height:100%; */
}
.link-box p {
  margin: 0px 0px 10px 0px;
}
.link-box img {
  height: 40px;
  margin-bottom: 10px;
}
.link-box-title {
  color: black;
  font-weight: bold;
  font-size: 18px;
}
.link-box-subtitle {
  color: black;
  font-size: 18px;
  margin-bottom: 10px;
}
</style>
