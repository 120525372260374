<template>
  <sidebar-menu
    :menu="menu"
    :width="`${width}px`"
    @toggle-collapse="$emit('toggle-collapse', $event)"
    @item-click="onItemClick"
    :collapsed="collapsed"
  />
</template>

<script>
  import firebase from 'firebase';
  import { SidebarMenu } from 'vue-sidebar-menu';
  import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';
  import SidebarLogoHeader from './SidebarLogoHeader.vue';
  export default {
    name: 'SideBar',
    props: ['width', 'collapsed'],
    components: {
      SidebarMenu,
    },
    data() {
      return {
        menu: [
          {
            component: SidebarLogoHeader,
          },
          {
            header: 'Main Navigation',
            hiddenOnCollapse: true,
          },

          {
            href: '/Dashboard',
            title: 'Dashboard',
            icon: 'fa fa-university',
          },
          {
            href: '/Werkplekken',
            title: 'Locatiebeheer',
            icon: 'fa fa-building ',
          },

          {
            href: '/Boekingen',
            title: 'Boekingen',
            icon: 'fa fa-shopping-cart',
          },

          {
            href: '/Agenda',
            title: 'Agenda',
            icon: 'fa fa-calendar',
          },

          {
            href: '/Mijn-Account',
            title: 'Mijn account',
            icon: 'fa fa-user',
          },

          {
            href: '/Facturen',
            title: 'Facturen',
            icon: 'fa fa-file-invoice',
          },
          //           {
          //   href: '/Instellingen',
          //   title: 'Instellingen',
          //   icon: 'fa fa-cog',
          // },
          {
            href: '/Plug-ins',
            title: 'Plug-ins',
            icon: 'fa fa-rocket',
          },

          {
            title: 'Uitloggen',
            icon: 'fa fa-power-off',
          },
        ],
      };
    },
    methods: {
      async logOut() {
        await firebase.auth().signOut();
        location.reload();
      },
      onItemClick(event, item) {
        if (item.title === 'Uitloggen') this.logOut();
      },
    },
  };
</script>
